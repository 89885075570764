import { Controller } from "@hotwired/stimulus";
//import LocomotiveScroll from 'locomotive-scroll';

export default class extends Controller {
    static targets = ["webSiteTitle", "titleAltiYazma", "buttonMore","normalTitle"];

    // Locomotive Scroll init ettik 
    // Kullanimi: scroll edecek elementin taginin icint 
    // data-scroll data-scroll-speed="0.5" yazabilirsin
    connect() {
        this.loadTranslations();
        //LocomotiveScroll = new LocomotiveScroll();
    }


    // currentLanguage kisminda 'en' yerini 'tr' yazarsan dil degismis olur
    async loadTranslations() {
        const currentLanguage = this.data.get("language") || "tr";
        const response = await fetch(`/locale/${currentLanguage}/transition.json`);
        console.log('response', response)
        const translations = await response.json();
        this.translations = translations;
        this.setTranslation();
    }

    // mutli language for transiition
    setTranslation() {
        for (const target of this.constructor.targets) {
            const translation = this.translations[target];
            if (translation) {
                const targetElements = this.targets.findAll(target);
                targetElements.forEach(element => {
                    element.textContent = translation;
                });
            }
        }
    }

    changeLanguage() {
        const currentLanguage = this.data.get("language") || "en";
        const newLanguage = currentLanguage === "en" ? "tr" : "en";
        this.data.set("language", newLanguage);
        this.loadTranslations();
    }
}
