import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["content"]

    toggle(event) {
        const button = event.currentTarget
        const content = button.nextElementSibling

        if (!button.parentElement.classList.contains('active')) {
            console.log('yes')
            this.closeAll(button.parentElement) // Kapamadan önce diğerlerini kapatın
            content.style.maxHeight = null
            content.classList.remove("hidden")
            button.parentElement.classList.add("active")
        } else {
            console.log('no')
            content.classList.add("hidden")
            button.parentElement.classList.remove("active")
        }
    }

    closeAll(exception) {
        console.log("exception => ",this.contentTargets)
        console.log("exception => ",exception)
        this.contentTargets.forEach(content => {
            console.log('content => ' , content)
            if (content !== exception) {
                content.style.maxHeight = null
                content.classList.add("hidden")
                content.parentElement.classList.remove("active")
            }
        })
    }
}