import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['menu', 'menuButton']
  connect() {

    $('.slick').slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: false,
      dots: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
          }
        }
      ]
    });
  }

  // menu open islemi 
  toggleMenu() {
    this.menuButtonTarget.classList.contains('show') ? this.menuButtonTarget.classList.remove('show') : this.menuButtonTarget.classList.add('show')
    this.menuTarget.classList.contains('menu-open') ? this.menuTarget.classList.remove('menu-open') : this.menuTarget.classList.add('menu-open')
    // this.menuTarget.classList.add('show');
    // body class olarak ekliyor, controller icin onemli
    //this.element.classList.add('menuOpen');
  }
}
